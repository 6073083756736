import type { PartnersSection } from "@/modules/corporate/types/sections"
import type { FunctionComponent } from "react"

import Image from "next/image"
import Marquee from "react-fast-marquee"

import { Button, BUTTON_VARIANT } from "@jobteaser/ui-kit/components/Button"
import { Layout } from "@jobteaser/ui-kit/components/Layout/Layout"

import { InternalLink } from "@/modules/corporate/components/InternalLink"
import { Title } from "@/modules/corporate/components/Sections/Title/Title"
import { URL_PATHNAME } from "@/modules/corporate/constants"
import { useCorporateTrackingClick } from "@/modules/corporate/tracking/useCorporateTrackingClick"
import { useLocale } from "@/modules/locales/useLocale"

import styles from "./Partners.module.css"

export type PartnersPropsType = {
  section: PartnersSection
}

export const Partners: FunctionComponent<PartnersPropsType> = ({ section: { title, ctaLink, ctaTitle, logos } }) => {
  const locale = useLocale()
  const trackClickEvent = useCorporateTrackingClick()

  if (!logos.length) {
    return null
  }

  return (
    <Layout hasTopPadding hasBottomPadding isFullWidth className={styles.main}>
      <Layout.Column className={styles.wrapper}>
        {!!title && <Title title={title} tag="h2" isCentered className={styles.title} />}

        <Marquee speed={60} gradient={false}>
          {logos.map(item => (
            <Image
              key={item.url}
              className={styles.logo}
              src={item.url}
              height={item.height || 80}
              width={item.width || 300}
              alt={item.description || "School logo"}
              sizes="(max-width: 48rem) 100vw, (max-width: 64rem) 50vw, 33vw"
            />
          ))}
        </Marquee>

        {!!ctaLink.length && (
          <Button
            className={styles.button}
            component={InternalLink}
            customTitle={ctaTitle || ctaLink[0].title}
            page={ctaLink[0]}
            variant={BUTTON_VARIANT.GHOST}
            onClick={() =>
              trackClickEvent("corporate_cta_slider_partners", {
                clicked_url: `/${locale}/${URL_PATHNAME}/${ctaLink[0].slug}`,
                visual_context: ctaTitle || ctaLink[0].title,
              })
            }
          />
        )}
      </Layout.Column>
    </Layout>
  )
}
